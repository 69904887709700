export const state = () => ({
  isCookieBannerVisible: false,
  isCookieModalVisible: false,
  isPersonModalVisible: false,
  isPartnerModalVisible: false,
  isJobApplicationModalVisible: false,
  videoBeingPlayedId: 0,
})

export const mutations = {
  hideCookieBanner(state) {
    state.isCookieBannerVisible = false
  },
  showCookieBanner(state) {
    state.isCookieBannerVisible = true
  },
  hideCookieModal(state) {
    state.isCookieModalVisible = false
  },
  showCookieModal(state) {
    state.isCookieModalVisible = true
  },
  hidePersonModal(state) {
    state.isPersonModalVisible = false
  },
  showPersonModal(state) {
    state.isPersonModalVisible = true
  },
  hidePartnerModal(state) {
    state.isPartnerModalVisible = false
  },
  showPartnerModal(state) {
    state.isPartnerModalVisible = true
  },
  hideJobApplicationModal(state) {
    state.isJobApplicationModalVisible = false
  },
  showJobApplicationModal(state) {
    state.isJobApplicationModalVisible = true
  },
  pauseAllVideos(state, exception) {
    state.videoBeingPlayedId = exception
  },
}

export const getters = {
  isScrollBarVisible(state, getters, rootState) {
    return (
      !state.isCookieModalVisible &&
      !state.isPersonModalVisible &&
      !state.isPartnerModalVisible &&
      !state.isJobApplicationModalVisible &&
      !rootState.header.isMobileMenuOpen
    )
  },
}
