export const CUSTOM_TYPES = {
  'services-root': {
    url: '/additional-services',
    title: 'Additional services',
  },
  'services-account-management': {
    url: '/additional-services/account-management',
    title: 'Account management',
  },
  'services-metrics': {
    url: '/additional-services/metrics',
    title: 'Metrics',
  },
  'services-fraud-prevention': {
    url: '/additional-services/fraud',
    title: 'Fraud',
  },
  'payments-confirmation-of-payee': {
    url: '/additional-services/cop',
    title: 'CoP',
  },
  on_us: {
    url: '/additional-services/on-us',
    title: 'On-us',
  },
  'payments-orchestration': {
    url: '/additional-services/orchestration',
    title: 'Orchestration',
  },
}
