
import themeMixin from '@/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    children: {
      type: Array,
      required: false,
      default: () => [],
    },
    isSelected: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    nonEmptyChildren() {
      return this.children.filter((childItem) => {
        if (childItem.children && childItem.children.length === 0) {
          return false
        }

        return true
      })
    },
    hasChildren() {
      return this.nonEmptyChildren?.length
    },
  },
  methods: {
    clickHandler() {
      this.$emit('select')
    },
  },
}
