import { render, staticRenderFns } from "./index.vue?vue&type=template&id=f97a56da&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsButton: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Button/index.vue').default,ComponentsLink: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Link/index.vue').default,ElementsCheckbox: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Checkbox/index.vue').default,ElementsButtonWithState: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Button/WithState.vue').default,ComponentsModal: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Modal/index.vue').default})
