export const CUSTOM_TYPES = {
  'cookie-policy': {
    url: '/legal/cookie-policy',
    title: 'Cookie Policy',
  },
  'data-privacy-statement': {
    url: '/legal/data-privacy-statement',
    title: 'Privacy Statements',
  },
  'license-page': {
    url: '/legal/license',
    title: 'License',
  },
  'modern-slavery': {
    url: '/legal/modern-slavery',
    title: 'Modern Slavery',
  },
  'recruitment-data-policy': {
    url: '/legal/recruitment-data-policy',
    title: 'Recruitment Policy',
  },
  'terms-and-conditions': {
    url: '/legal/terms-and-conditions',
    title: 'Terms and Conditions',
  },
}
