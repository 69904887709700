import { render, staticRenderFns } from "./Section.vue?vue&type=template&id=072af386&"
import script from "./Section.vue?vue&type=script&lang=js&"
export * from "./Section.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentsNavigationHeaderV2MobileTabsSectionLink: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Navigation/HeaderV2/Mobile/Tabs/SectionLink.vue').default})
