import { isNewPost } from './utils'
import fetchDynamicPages from '@/buildModules/fetchDynamicPages'

const customTypeToStateKeyMap = {
  marketing_insights_post: 'insights',
  engineering_update_post: 'engineeringUpdates',
  press_post: 'press',
  people_post: 'people',
}
export const latestPostCount = {
  insights: 4,
  engineeringUpdates: 4,
  press: 4,
  people: 4,
}
export const state = () => ({
  insights: {
    documents: [],
  },
  engineeringUpdates: {
    documents: [],
  },
  press: {
    documents: [],
  },
  people: {
    documents: [],
  },
  errorPageData: null,
})

export const mutations = {
  // Sets the summary data for all posts of type updates
  // to be used on the list page
  setDynamicPages(state, { documents, repeatableType }) {
    const storeState = state[customTypeToStateKeyMap[repeatableType]]
    if (documents) {
      const mapped = (documents || []).map((document) => {
        return {
          id: document.id,
          post_type: document.post_type,
          slug: document.slug,
          card_title: document.card_title,
          card_description: document.card_description,
          card_image: document.card_image,
          reading_time: document.reading_time,
          first_publication_date: document.first_publication_date,
          last_publication_date: document.last_publication_date,
        }
      })
      storeState.documents = mapped
    }
  },
  setErrorPageData(state, errorPageData) {
    state.errorPageData = errorPageData
  },
}

export const getters = {
  insightsTags(state) {
    return [
      ...new Set(
        state.insights.documents.map((document) => document.post_type)
      ),
    ]
  },
  latestInsights(state, getters) {
    return getters.insightsTags.reduce((result, postType) => {
      return {
        ...result,
        [postType]: state.insights.documents
          // eslint-disable-next-line camelcase
          .filter(({ post_type }) => post_type === postType)
          .slice(0, latestPostCount.insights),
      }
    }, {})
  },
  hasLatestInsights(state, getters) {
    return Object.entries(getters.latestInsights).reduce(
      (result, [postType, latestArray]) => {
        return {
          ...result,
          [postType]:
            latestArray && latestArray.length === latestPostCount.insights,
        }
      },
      {}
    )
  },
  engineeringUpdatesTags(state) {
    return [
      ...new Set(
        state.engineeringUpdates.documents.map((document) => document.post_type)
      ),
    ]
  },
  latestEngineeringUpdates(state, getters) {
    return getters.engineeringUpdatesTags.reduce((result, postType) => {
      return {
        ...result,
        [postType]: state.engineeringUpdates.documents
          // eslint-disable-next-line camelcase
          .filter(({ post_type }) => post_type === postType)
          .slice(0, latestPostCount.engineeringUpdates),
      }
    }, {})
  },
  hasLatestEngineeringUpdates(state, getters) {
    return Object.entries(getters.latestEngineeringUpdates).reduce(
      (result, [postType, latestArray]) => {
        return {
          ...result,
          [postType]:
            latestArray &&
            latestArray.length === latestPostCount.engineeringUpdates,
        }
      },
      {}
    )
  },
  pressTags(state) {
    return [
      ...new Set(state.press.documents.map((document) => document.post_type)),
    ]
  },
  latestPress(state, getters) {
    return getters.pressTags.reduce((result, postType) => {
      return {
        ...result,
        [postType]: state.press.documents
          // eslint-disable-next-line camelcase
          .filter(({ post_type }) => post_type === postType)
          .slice(0, latestPostCount.press),
      }
    }, {})
  },
  hasLatestPress(state, getters) {
    return Object.entries(getters.latestPress).reduce(
      (result, [postType, latestArray]) => {
        return {
          ...result,
          [postType]:
            latestArray && latestArray.length === latestPostCount.press,
        }
      },
      {}
    )
  },
  peopleTags(state) {
    return [
      ...new Set(state.people.documents.map((document) => document.post_type)),
    ]
  },
  latestPeople(state, getters) {
    return getters.peopleTags.reduce((result, postType) => {
      return {
        ...result,
        [postType]: state.people.documents
          // eslint-disable-next-line camelcase
          .filter(({ post_type }) => post_type === postType)
          .slice(0, latestPostCount.people),
      }
    }, {})
  },
  hasLatestPeople(state, getters) {
    return Object.entries(getters.latestPeople).reduce(
      (result, [postType, latestArray]) => {
        return {
          ...result,
          [postType]:
            latestArray && latestArray.length === latestPostCount.people,
        }
      },
      {}
    )
  },
  errorPageDataSlices(state) {
    return state.errorPageData?.data?.slices || []
  },
  hasNewPressPost(state) {
    return isNewPost(state.press.documents[0])
  },
  hasNewInsightsPost(state) {
    return isNewPost(state.insights.documents[0])
  },
  hasNewEngineeringBlogPost(state) {
    return isNewPost(state.engineeringUpdates.documents[0])
  },
  hasNewPeopleBlogPost(state) {
    return isNewPost(state.people.documents[0])
  },
}

export const actions = {
  async loadAllInsights({ commit, rootState }) {
    const { prismicGraphQLEndpoint } = rootState.config
    const documents = await fetchDynamicPages(
      prismicGraphQLEndpoint,
      'marketing_insights_post'
    )

    commit('setDynamicPages', {
      documents,
      repeatableType: 'marketing_insights_post',
    })
  },

  async loadLatestInsights({ getters, dispatch }, postType) {
    if (getters.hasLatestInsights[postType]) {
      return
    }

    return await dispatch('loadAllInsights')
  },
  async loadAllEngineeringUpdates({ commit, rootState }) {
    const { prismicGraphQLEndpoint } = rootState.config
    const documents = await fetchDynamicPages(
      prismicGraphQLEndpoint,
      'engineering_update_post'
    )

    commit('setDynamicPages', {
      document: null,
      documents,
      repeatableType: 'engineering_update_post',
    })
  },

  async loadLatestEngineeringUpdates({ getters, dispatch }, postType) {
    if (getters.hasLatestEngineeringUpdates[postType]) {
      return
    }

    return await dispatch('loadAllEngineeringUpdates')
  },

  async loadAllPress({ commit, rootState }) {
    const { prismicGraphQLEndpoint } = rootState.config
    const documents = await fetchDynamicPages(
      prismicGraphQLEndpoint,
      'press_post'
    )

    commit('setDynamicPages', {
      document: null,
      documents,
      repeatableType: 'press_post',
    })
  },
  async loadLatestPress({ getters, dispatch }, postType) {
    if (getters.hasLatestPress[postType]) {
      return
    }

    return await dispatch('loadAllPress')
  },
  async loadAllPeople({ commit, rootState }) {
    const { prismicGraphQLEndpoint } = rootState.config
    const documents = await fetchDynamicPages(
      prismicGraphQLEndpoint,
      'people_post'
    )

    commit('setDynamicPages', {
      documents,
      repeatableType: 'people_post',
    })
  },

  async loadLatestPeople({ getters, dispatch }, postType) {
    if (getters.hasLatestPeople[postType]) {
      return
    }

    return await dispatch('loadAllPeople')
  },

  setErrorPageData({ commit }, errorPageData) {
    commit('setErrorPageData', errorPageData)
  },
}
