
import { mapGetters } from 'vuex'
import { isExternalLink } from '@/utils/links'
import themeMixin from '@/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    url: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
    notification: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    ...mapGetters('pages', {
      hasNewPressPost: 'hasNewPressPost',
      hasNewInsightsPost: 'hasNewInsightsPost',
      hasNewEngineeringBlogPost: 'hasNewEngineeringBlogPost',
      hasNewPeopleBlogPost: 'hasNewPeopleBlogPost',
    }),
    isExternalLink() {
      return isExternalLink(this.url)
    },
    computedNotification() {
      if (this.notification) {
        return this.notification
      }

      if (this.url === '/news/press-releases' && this.hasNewPressPost) {
        return 'new'
      }

      if (this.url === '/news/payment-insights' && this.hasNewInsightsPost) {
        return 'new'
      }

      if (this.url === '/blog/engineering' && this.hasNewEngineeringBlogPost) {
        return 'new'
      }

      if (this.url === '/blog/culture' && this.hasNewPeopleBlogPost) {
        return 'new'
      }

      return null
    },
  },
}
