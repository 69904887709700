export const state = () => ({
  color: 'dark-navy',
  contrast: 'soft-white',
  currentThemeIconContrast: 'light',
  contrasts: {
    azure: 'dark-navy',
    coral: 'dark-navy',
    teal: 'dark-navy',
    'dark-navy': 'soft-white',
    'soft-white': 'dark-navy',
    'sky-blue': 'dark-navy',
    'ocean-blue': 'soft-white',
  },
  iconContrastToTheme: {
    azure: 'dark',
    coral: 'dark',
    teal: 'dark',
    'soft-white': 'dark',
    'sky-blue': 'dark',
    'dark-navy': 'light',
    'ocean-blue': 'light',
  },
  // returns a similar but "safe" color
  // to be used as a background on a component
  // relative to the current pages theme
  accessibilityContrast: {
    azure: 'ocean-blue',
    coral: 'soft-white',
    teal: 'ocean-blue',
    'soft-white': 'sky-blue',
    'sky-blue': 'soft-white',
    'dark-navy': 'ocean-blue',
    'ocean-blue': 'dark-navy',
  },
})

export const mutations = {
  setTheme(state, payload) {
    state.color = payload
    state.contrast = state.contrasts[payload]
    state.currentThemeIconContrast = state.iconContrastToTheme[payload]
  },
}

export const getters = {
  contrast(state) {
    return state.contrasts[state.color]
  },
  iconContrast(state) {
    return state.iconContrastToTheme[state.color]
  },
  isLightTheme(state, getters) {
    return getters.iconContrast === 'dark'
  },
  isDarkTheme(state, getters) {
    return !getters.isLightTheme
  },
}
