
export default {
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    children: {
      type: Array,
      required: false,
      default: () => [],
    },
    withExtraMargin: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
