import { render, staticRenderFns } from "./Dropdown.vue?vue&type=template&id=6f535060&scoped=true&"
import script from "./Dropdown.vue?vue&type=script&lang=js&"
export * from "./Dropdown.vue?vue&type=script&lang=js&"
import style0 from "./Dropdown.vue?vue&type=style&index=0&id=6f535060&prod&lang=postcss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6f535060",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentsNavigationHeaderV2DesktopPage: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Navigation/HeaderV2/Desktop/Page.vue').default})
