import { ApolloClient, InMemoryCache, gql } from '@apollo/client/core'
import { createPrismicLink } from 'apollo-link-prismic'
import fetch from 'node-fetch'

let apolloClient = null
const isOnServer =
  process.server ||
  (process.server === undefined && process.client === undefined)

const getAPIClient = (apiUrl) => {
  if (!apolloClient) {
    apolloClient = new ApolloClient({
      link: createPrismicLink({
        uri: apiUrl,
        fetch: isOnServer ? fetch : window?.fetch,
      }),
      cache: new InMemoryCache(),
    })
  }
  return apolloClient
}
const queries = {
  marketing_insights_post: {
    query: (lastCursor, itemsPerPage) => `
      query {
        allMarketing_insights_posts(after: "${lastCursor}" first: ${itemsPerPage}) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              _meta {
                uid
                id
                firstPublicationDate
                lastPublicationDate
              }
              card_description
              card_title
              card_image
              reading_time
              post_type {
                ... on Insights_tag {
                  name
                }
              }
              published_date
            }
          }
        }
      }
    `,
  },
  marketing_campaign_post: {
    query: (lastCursor, itemsPerPage) => `
      query {
        allMarketing_campaign_posts(after: "${lastCursor}" first: ${itemsPerPage}) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              _meta {
                uid
                id
              }
            }
          }
        }
      }
    `,
  },
  marketing_landing_post: {
    query: (lastCursor, itemsPerPage) => `
      query {
        allMarketing_landing_posts(after: "${lastCursor}" first: ${itemsPerPage}) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              _meta {
                uid
                id
              }
            }
          }
        }
      }
    `,
  },
  engineering_update_post: {
    query: (lastCursor, itemsPerPage) => `
      query {
        allEngineering_update_posts(after: "${lastCursor}" first: ${itemsPerPage}) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              _meta {
                uid
                id
                firstPublicationDate
                lastPublicationDate
              }
              card_description
              card_title
              card_image
              reading_time
              post_type {
                ... on Engineering_updates_tag {
                  name
                }
              }
              published_date
            }
          }
        }
      }
    `,
  },
  engineering_who_we_are_post: {
    query: (lastCursor, itemsPerPage) => `
      query {
        allEngineering_who_we_are_posts(after: "${lastCursor}" first: ${itemsPerPage}) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              _meta {
                uid
                id
              }
            }
          }
        }
      }
    `,
  },
  press_post: {
    query: (lastCursor, itemsPerPage) => `
      query {
        allPress_posts(after: "${lastCursor}" first: ${itemsPerPage}) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              _meta {
                uid
                id
                firstPublicationDate
                lastPublicationDate
              }
              card_description
              card_title
              card_image
              reading_time
              post_type {
                ... on Press_tag {
                  name
                }
              }
              published_date
            }
          }
        }
      }
    `,
  },
  people_post: {
    query: (lastCursor, itemsPerPage) => `
      query {
        allPeople_posts(after: "${lastCursor}" first: ${itemsPerPage}) {
          pageInfo {
            hasNextPage
            endCursor
          }
          edges {
            node {
              _meta {
                uid
                id
                firstPublicationDate
                lastPublicationDate
              }
              card_description
              card_title
              card_image
              reading_time
              post_type {
                ... on People_tag {
                  name
                }
              }
              published_date
            }
          }
        }
      }
    `,
  },
}

const mapResponse = (documentType, node) => {
  if (
    documentType === 'marketing_campaign_post' ||
    documentType === 'engineering_who_we_are_post' ||
    documentType === 'marketing_landing_post'
  ) {
    return {
      id: node._meta.id,
      slug: node._meta.uid,
    }
  }

  return {
    id: node._meta.id,
    slug: node._meta.uid,
    card_title: node.card_title,
    card_image: node.card_image,
    card_description: node.card_description,
    first_publication_date:
      node.published_date || node._meta.firstPublicationDate,
    last_publication_date: node._meta.lastPublicationDate,
    reading_time: node.reading_time,
    post_type: node.post_type.name,
  }
}

const responseTransform = (documentType) => (results) => {
  const documentTypePaths = {
    marketing_insights_post: 'allMarketing_insights_posts',
    marketing_campaign_post: 'allMarketing_campaign_posts',
    marketing_landing_post: 'allMarketing_landing_posts',
    engineering_update_post: 'allEngineering_update_posts',
    engineering_who_we_are_post: 'allEngineering_who_we_are_posts',
    press_post: 'allPress_posts',
    people_post: 'allPeople_posts',
  }
  return {
    hasNextPage:
      results.data[documentTypePaths[documentType]].pageInfo.hasNextPage,
    endCursor: results.data[documentTypePaths[documentType]].pageInfo.endCursor,
    results: results.data[documentTypePaths[documentType]].edges.map(
      ({ node }) => {
        return mapResponse(documentType, node)
      }
    ),
  }
}

export default async function (apiUrl, documentType) {
  const ITEMS_PER_PAGE = 100
  const dynamicPosts = async (lastCursor = null, set = []) => {
    const posts = set
    try {
      const currentSet = await queryDynamicPost(apiUrl, {
        lastCursor,
        itemsPerPage: ITEMS_PER_PAGE,
      })

      posts.push(currentSet)

      if (currentSet.hasNextPage) {
        const lastPageCursor = currentSet.endCursor
        return dynamicPosts(lastPageCursor, posts)
      }
      return parseResults(posts)
    } catch (err) {
      throw new Error('there was an error while fetching dynamic pages', {
        err,
        apiUrl,
        documentType,
      })
    }
  }

  const parseResults = (pages) => {
    return pages
      .reduce((acc, page) => {
        acc.push(...page.results)
        return acc
      }, [])
      .sort((post1, post2) => {
        return (
          new Date(post2.first_publication_date).getTime() -
          new Date(post1.first_publication_date).getTime()
        )
      })
  }

  const queryDynamicPost = (apiUrl, { lastCursor, itemsPerPage }) => {
    const { query } = queries[documentType]
    return getAPIClient(apiUrl)
      .query({
        query: gql`
          ${query(lastCursor, itemsPerPage)}
        `,
      })
      .then(responseTransform(documentType))
      .then((parsedResults) => {
        return parsedResults
      })
  }

  const results = await dynamicPosts()

  return results
}
