export const state = () => ({
  isDesktopMenuOpen: false,
  isMobileMenuOpen: false,
  selectedDesktopMenu: null,
})

export const mutations = {
  toggleMobileMenu(state) {
    state.isMobileMenuOpen = !state.isMobileMenuOpen
  },
  closeMobileMenu(state) {
    state.isMobileMenuOpen = false
  },
  showDesktopMenu(state) {
    state.isDesktopMenuOpen = true
  },
  closeDesktopMenu(state) {
    state.isDesktopMenuOpen = false
  },
  selectDesktopMenu(state, value) {
    state.selectedDesktopMenu = value
  },
}

export const actions = {
  closeMenu({ commit, getters, state }) {
    commit('closeMobileMenu')
    commit('closeDesktopMenu')
    commit('selectDesktopMenu', null)
  },
  closeDesktopMenu({ commit }) {
    commit('selectDesktopMenu', null)
    commit('closeDesktopMenu')
  },
  selectDesktopMenu({ commit }, payload) {
    commit('selectDesktopMenu', payload)
    commit('showDesktopMenu')
  },
}
