
export default {
  name: 'HamburgerMenuButton',
  props: {
    isOpened: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
}
