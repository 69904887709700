
import { mapState } from 'vuex'
import themeMixin from '@/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    sitemap: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    ...mapState('header', {
      showBackdrop: 'isDesktopMenuOpen',
    }),
  },
}
