
import { mapMutations, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapGetters('pages', {
      hasNewPressPost: 'hasNewPressPost',
      hasNewInsightsPost: 'hasNewInsightsPost',
      hasNewEngineeringBlogPost: 'hasNewEngineeringBlogPost',
      hasNewPeopleBlogPost: 'hasNewPeopleBlogPost',
    }),
    ...mapGetters('theme', {
      isDarkTheme: 'isDarkTheme',
    }),
    background() {
      return this.$store.state.theme.color
    },
    text() {
      return this.$store.state.theme.contrast
    },
    grayText() {
      if (this.isDarkTheme) {
        return 'gray-400'
      }

      return 'gray-500'
    },
    tealText() {
      if (this.isDarkTheme) {
        return 'teal'
      }

      return 'teal-darker'
    },
    iconTheme() {
      return this.$store.state.theme.currentThemeIconContrast
    },
    isHiring() {
      // return this.$store.state.featureFlags.show_careers_alert
      return true // this is currently hardcoded in the menu as well, leaving it like this for now
    },
  },
  methods: {
    ...mapMutations('behaviour', {
      showCookieBanner: 'showCookieBanner',
    }),
  },
}
