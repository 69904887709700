import {
  parseMenuEntrySlice,
  parseMenuTabsSlice,
  menuEntryType,
  parseMenuAdvertSlice,
} from './utils'
export const state = () => ({})

export const mutations = {
  setMenu(state, menuData) {
    state.menuData = menuData
  },
}

export const actions = {
  setMenu({ commit }, menuData) {
    commit('setMenu', menuData)
  },
}

export const getters = {
  mobileMenu(state) {
    try {
      return JSON.parse(state.menuData.mobileMenuJson[0].text)
    } catch (err) {
      throw new Error(
        `mobileMenu parse error ${err}, update sitemap on prismic`
      )
    }
  },
  desktopMenu(state) {
    if (!state.menuData || !state.menuData.slices) {
      throw new Error(
        `desktopMenu parse error: there are no menu entries on the desktop menu`
      )
    }
    const desktopMenuData = state.menuData.slices.reduce((acc, item) => {
      if (item.slice_type === menuEntryType) {
        acc.push(parseMenuEntrySlice(item))
      } else {
        const lastMenuItem = acc[acc.length - 1]
        if (lastMenuItem) {
          if (item.slice_type === 'menu_tabs') {
            lastMenuItem.widget = {
              slices: [parseMenuTabsSlice(item)],
            }
          } else {
            lastMenuItem.widget = parseMenuAdvertSlice(
              item,
              lastMenuItem.widget?.slices
            )
          }
        }
      }

      return acc
    }, [])

    return desktopMenuData
  },
}
