
export default {
  props: {
    title: {
      type: String,
      required: true,
      default: 'default title',
    },
  },
  methods: {
    closeButtonClickHandler() {
      this.$emit('close')
    },
  },
}
