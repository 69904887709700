
import themeMixin from '@/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    children: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      selectedTabIndex: null,
    }
  },
  methods: {
    onSelectTab(index) {
      if (this.selectedTabIndex === index) {
        this.selectedTabIndex = null
      } else {
        this.selectedTabIndex = index
      }
    },
  },
}
