import { loadFeatureFlags } from '~/utils/loadFeatureFlags'
import { loadMenu } from '~/utils/loadMenu'
import { loadLatestDynamicPosts } from '~/utils/loadLatestDynamicPosts'
import { loadErrorPage } from '~/utils/loadErrorPage'
import { getLanguageFromRoute } from '~/utils/language'

export const actions = {
  async nuxtServerInit({ commit }, context) {
    const { prismicGraphQLEndpoint } = context.$config

    const { $prismic, store, route } = context
    const lang = getLanguageFromRoute(route)
    await loadFeatureFlags($prismic, store, { lang })
    await loadLatestDynamicPosts(store)
    await loadMenu($prismic, store)
    await loadErrorPage($prismic, store)

    commit('config/update', { prismicGraphQLEndpoint })
  },
}
