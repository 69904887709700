export const CUSTOM_TYPES = {
  accreditations: {
    url: '/engineering/accreditations',
    title: 'Accreditations',
  },
  engineering: {
    url: '/engineering',
    title: 'Engineering',
  },
  'engineering-life-at-form3': {
    url: '/engineering/life-at-form3',
    title: 'Engineering: Life at Form3',
  },
}
// dynamic pages that are "hidden" from the menu/sitemap
// only accessible if someone shares the link with you
export const DYNAMIC_CUSTOM_TYPES = {
  engineering_who_we_are_post: {
    url: (uid) => {
      return `/engineering/who-we-are/${uid}`
    },
    title: 'Who We Are',
  },
}
