
import { mapState, mapMutations } from 'vuex'
import themeMixin from '~/mixins/theme'

export default {
  name: 'NavigationHeaderV2Mobile',
  mixins: [themeMixin],
  props: {
    sitemap: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      selectedLinkIndex: -1,
    }
  },
  computed: {
    ...mapState('header', {
      isMenuOpen: 'isMobileMenuOpen',
    }),
    selectedLink() {
      return this.sitemap[this.selectedLinkIndex]
    },
    childrenLinks() {
      if (!this.selectedLink) {
        return []
      }

      return this.selectedLink.children
    },
    backgroundColor() {
      if (this.isDarkTheme) {
        return 'ocean-blue'
      }

      return this.themeColor
    },
  },
  watch: {
    isMenuOpen() {
      this.selectedLinkIndex = -1
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  },
  methods: {
    ...mapMutations('header', {
      toggleMenu: 'toggleMobileMenu',
      closeMenu: 'closeMobileMenu',
    }),
    goBack() {
      this.selectLink(-1)
    },
    selectLink(index) {
      this.selectedLinkIndex = index
    },
    handleResize() {
      const isDesktopSize = document.documentElement.clientWidth >= 1024

      if (isDesktopSize) {
        this.closeMenu()
      }
    },
  },
}
