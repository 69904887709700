export const menuEntryType = 'menu_entry'
export const tabsComponentType = 'tabs'
export const advertSliceType = 'advert'

export const parseMenuEntrySlice = (item) => ({
  title: item.primary.title,
  layout: item.primary.layout,
  type: menuEntryType,
  children: JSON.parse(item.primary.json[0].text),
})

export const parseMenuTabsSlice = (item) => ({
  title: item.primary.title,
  type: tabsComponentType,
  slice_type: 'menu_tabs',
  tabs: JSON.parse(item.primary.json[0].text),
})

export const parseMenuAdvertSlice = (item, linkedSliceArray = []) => ({
  slices: [...linkedSliceArray, item],
  type: advertSliceType,
})
