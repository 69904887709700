
import { components } from '~/slices'

export default {
  props: {
    children: {
      type: Array,
      required: false,
      default: () => [],
    },
    layout: {
      type: String,
      default: '',
      required: false,
    },
    widget: {
      type: Object,
      default: null,
      required: false,
    },
  },
  data() {
    return { components }
  },
}
