import { render, staticRenderFns } from "./index.vue?vue&type=template&id=4fe92fb3&"
import script from "./index.vue?vue&type=script&lang=js&"
export * from "./index.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentsLink: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Link/index.vue').default,ComponentsTogglesAccordion: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Toggles/Accordion/index.vue').default,ElementsIcon: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Icon/index.vue').default,ElementsButton: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Button/index.vue').default,LayoutSpacingHorizontal: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Layout/Spacing/Horizontal.vue').default})
