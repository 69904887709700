import { mapState, mapGetters } from 'vuex'

export default {
  computed: {
    ...mapState('theme', {
      themeColor: 'color',
      themeContrasts: 'contrasts',
      themeAccessibilityContrast: 'accessibilityContrast',
      iconContrasts: 'iconContrastToTheme',
      currentThemeIconContrast: 'currentThemeIconContrast',
    }),
    ...mapGetters('theme', {
      themeContrast: 'contrast',
      themeIconContrast: 'iconContrast',
      isLightTheme: 'isLightTheme',
      isDarkTheme: 'isDarkTheme',
    }),
    themeComputedClass() {
      return this.getThemeClass(this.themeColor)
    },
  },
  methods: {
    getContrastColor(theme) {
      return this.themeContrasts[theme] || this.themeContrast
    },
    getThemeClass(theme) {
      return `bg-${theme} text-${this.getContrastColor(theme)}`
    },
    getIconContrastColor(theme) {
      return this.iconContrasts[theme || this.themeColor]
    },
    getAccessibilityContrast(color) {
      if (color === this.themeColor) {
        return this.themeAccessibilityContrast[color] || color
      }

      return color
    },
  },
}
