
import themeMixin from '@/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    sections: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  methods: {
    hasChildren(item) {
      return item.children && item.children.length > 0
    },
  },
}
