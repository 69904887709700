
export const STATES = {
  default: '',
  loading: 'loading',
  error: 'error',
  success: 'success',
}

const iconMap = {
  [STATES.default]: 'arrow-right',
  [STATES.loading]: 'loading',
  [STATES.error]: 'error',
  [STATES.success]: 'success',
}

export default {
  props: {
    iconClass: {
      type: String,
      required: false,
      default: '',
    },
    state: {
      type: String,
      required: false,
      default: STATES.default,
    },
    theme: {
      type: String,
      required: false,
      default: 'soft-white',
    },
  },
  computed: {
    icon() {
      return iconMap[this.state]
    },
    shouldSpinIcon() {
      return this.state === STATES.loading
    },
    alwaysShowIcon() {
      return this.state !== STATES.default
    },
  },
}
