export const loadFeatureFlags = async ($prismic, store, options) => {
  try {
    const featureFlags = await $prismic.api.getByUID(
      'feature_flags',
      'feature-flags-uid',
      options
    )
    if (featureFlags) {
      store.dispatch('featureFlags/setFlags', featureFlags.data)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching feature flags', e)
  }
}
