// Code generated by Slice Machine. DO NOT EDIT.

export const components = {
  anchor: () =>
    import(
      /* webpackChunkName: "prismic__slices__anchor" */ './Anchor/index.vue'
    ),
  author: () =>
    import(
      /* webpackChunkName: "prismic__slices__author" */ './Author/index.vue'
    ),
  button_link: () =>
    import(
      /* webpackChunkName: "prismic__slices__button_link" */ './ButtonLink/index.vue'
    ),
  card_with_tabbed_text_list: () =>
    import(
      /* webpackChunkName: "prismic__slices__card_with_tabbed_text_list" */ './CardWithTabbedTextList/index.vue'
    ),
  cards_group: () =>
    import(
      /* webpackChunkName: "prismic__slices__cards_group" */ './CardsGroup/index.vue'
    ),
  cards_with_images: () =>
    import(
      /* webpackChunkName: "prismic__slices__cards_with_images" */ './CardsWithImages/index.vue'
    ),
  client_testimonials: () =>
    import(
      /* webpackChunkName: "prismic__slices__client_testimonials" */ './ClientTestimonials/index.vue'
    ),
  clients_logo_list: () =>
    import(
      /* webpackChunkName: "prismic__slices__clients_logo_list" */ './ClientsLogoList/index.vue'
    ),
  code_snippet: () =>
    import(
      /* webpackChunkName: "prismic__slices__code_snippet" */ './CodeSnippet/index.vue'
    ),
  company_milestones: () =>
    import(
      /* webpackChunkName: "prismic__slices__company_milestones" */ './CompanyMilestones/index.vue'
    ),
  contact_form: () =>
    import(
      /* webpackChunkName: "prismic__slices__contact_form" */ './ContactForm/index.vue'
    ),
  events: () =>
    import(
      /* webpackChunkName: "prismic__slices__events" */ './Events/index.vue'
    ),
  hero_card: () =>
    import(
      /* webpackChunkName: "prismic__slices__hero_card" */ './HeroCard/index.vue'
    ),
  image_carrousel: () =>
    import(
      /* webpackChunkName: "prismic__slices__image_carrousel" */ './ImageCarrousel/index.vue'
    ),
  image_with_description: () =>
    import(
      /* webpackChunkName: "prismic__slices__image_with_description" */ './ImageWithDescription/index.vue'
    ),
  latest_jobs: () =>
    import(
      /* webpackChunkName: "prismic__slices__latest_jobs" */ './LatestJobs/index.vue'
    ),
  latest_posts: () =>
    import(
      /* webpackChunkName: "prismic__slices__latest_posts" */ './LatestPosts/index.vue'
    ),
  legal_basis_table: () =>
    import(
      /* webpackChunkName: "prismic__slices__legal_basis_table" */ './LegalBasisTable/index.vue'
    ),
  legal_cookies_table: () =>
    import(
      /* webpackChunkName: "prismic__slices__legal_cookies_table" */ './LegalCookiesTable/index.vue'
    ),
  legal_individuals_rights_table: () =>
    import(
      /* webpackChunkName: "prismic__slices__legal_individuals_rights_table" */ './LegalIndividualsRightsTable/index.vue'
    ),
  legal_information_collection_table: () =>
    import(
      /* webpackChunkName: "prismic__slices__legal_information_collection_table" */ './LegalInformationCollectionTable/index.vue'
    ),
  legal_page_top_section: () =>
    import(
      /* webpackChunkName: "prismic__slices__legal_page_top_section" */ './LegalPageTopSection/index.vue'
    ),
  levels: () =>
    import(
      /* webpackChunkName: "prismic__slices__levels" */ './Levels/index.vue'
    ),
  license_table: () =>
    import(
      /* webpackChunkName: "prismic__slices__license_table" */ './LicenseTable/index.vue'
    ),
  map: () =>
    import(/* webpackChunkName: "prismic__slices__map" */ './Map/index.vue'),
  media_and_text: () =>
    import(
      /* webpackChunkName: "prismic__slices__media_and_text" */ './MediaAndText/index.vue'
    ),
  media_and_text_tabs: () =>
    import(
      /* webpackChunkName: "prismic__slices__media_and_text_tabs" */ './MediaAndTextTabs/index.vue'
    ),
  menu_entry: () =>
    import(
      /* webpackChunkName: "prismic__slices__menu_entry" */ './MenuEntry/index.vue'
    ),
  menu_tabs: () =>
    import(
      /* webpackChunkName: "prismic__slices__menu_tabs" */ './MenuTabs/index.vue'
    ),
  menu_widget: () =>
    import(
      /* webpackChunkName: "prismic__slices__menu_widget" */ './MenuWidget/index.vue'
    ),
  numbered_card_with_person_and_link: () =>
    import(
      /* webpackChunkName: "prismic__slices__numbered_card_with_person_and_link" */ './NumberedCardWithPersonAndLink/index.vue'
    ),
  page_header: () =>
    import(
      /* webpackChunkName: "prismic__slices__page_header" */ './PageHeader/index.vue'
    ),
  partners_carrousel: () =>
    import(
      /* webpackChunkName: "prismic__slices__partners_carrousel" */ './PartnersCarrousel/index.vue'
    ),
  podcast: () =>
    import(
      /* webpackChunkName: "prismic__slices__podcast" */ './Podcast/index.vue'
    ),
  quote: () =>
    import(
      /* webpackChunkName: "prismic__slices__quote" */ './Quote/index.vue'
    ),
  reject_all_cookies_button: () =>
    import(
      /* webpackChunkName: "prismic__slices__reject_all_cookies_button" */ './RejectAllCookiesButton/index.vue'
    ),
  share_social: () =>
    import(
      /* webpackChunkName: "prismic__slices__share_social" */ './ShareSocial/index.vue'
    ),
  team_gallery: () =>
    import(
      /* webpackChunkName: "prismic__slices__team_gallery" */ './TeamGallery/index.vue'
    ),
  title_and_numbered_text: () =>
    import(
      /* webpackChunkName: "prismic__slices__title_and_numbered_text" */ './TitleAndNumberedText/index.vue'
    ),
  title_and_text_section: () =>
    import(
      /* webpackChunkName: "prismic__slices__title_and_text_section" */ './TitleAndTextSection/index.vue'
    ),
  video_carousel: () =>
    import(
      /* webpackChunkName: "prismic__slices__video_carousel" */ './VideoCarousel/index.vue'
    ),
  video_embed: () =>
    import(
      /* webpackChunkName: "prismic__slices__video_embed" */ './VideoEmbed/index.vue'
    ),
}
