export const loadErrorPage = async ($prismic, store) => {
  try {
    const errorPageData = await $prismic.api.getSingle('404')
    if (errorPageData) {
      store.dispatch('pages/setErrorPageData', errorPageData)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching error page', e)
  }
}
