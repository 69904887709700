export const sha256 = async (text) => {
  const textBuffer = new TextEncoder().encode(text)
  const hashBuffer = await crypto.subtle.digest('SHA-256', textBuffer)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, '0')).join('')
  return hashHex
}

export const djb2 = (str = []) => {
  const len = str?.length
  let hash = 5381
  for (let idx = 0; idx < len; ++idx) {
    hash = 33 * hash + str.charCodeAt(idx)
  }
  return hash
}
