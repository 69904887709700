
export default {
  props: {
    theme: {
      type: String,
      required: false,
      default: null,
    },
    isFullWidth: {
      type: Boolean,
      required: false,
      default: false,
    },
    iconClass: {
      type: String,
      required: false,
      default: '',
    },
    containerClass: {
      type: String,
      required: false,
      default: '',
    },
    customIconTheme: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    background() {
      return this.theme || this.$store.state.theme.color
    },
    iconTheme() {
      return (
        this.customIconTheme ||
        this.$store.state.theme.iconContrastToTheme[this.background]
      )
    },
  },
}
