export const state = () => ({})

export const mutations = {
  setFlag(state, { key, value }) {
    state[key] = value
  },
}

export const actions = {
  setFlags({ commit }, flagObjectMap) {
    Object.entries(flagObjectMap).forEach(([key, value]) =>
      commit('setFlag', { key, value })
    )
  },
}

export const getters = {
  sitemap(state) {
    try {
      return JSON.parse(state.sitemap[0].text)
    } catch (err) {
      throw new Error(`Sitemap parse error ${err}, update sitemap on prismic`)
    }
  },
}
