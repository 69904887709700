
import { mapGetters } from 'vuex'
import { components } from '~/slices'

export default {
  props: {
    error: {
      type: Object,
      required: true,
    },
  },
  data() {
    return { components }
  },
  computed: {
    ...mapGetters('pages', {
      slices: 'errorPageDataSlices',
    }),
  },
  beforeCreate() {
    this.$store.commit('theme/setTheme', 'dark-navy')
  },
}
