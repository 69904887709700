
import { isExternalLink } from '@/utils/links'
import themeMixin from '@/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    title: {
      type: String,
      required: false,
      default: null,
    },
    url: {
      type: String,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    tags: {
      type: Array,
      required: false,
      default: () => [],
    },
    notification: {
      type: String,
      required: false,
      default: null,
    },
  },
  computed: {
    isExternalLink() {
      return isExternalLink(this.url)
    },
    hasChildren() {
      return this.children?.length
    },
    computedChildLinks() {
      if (!this.hasChildren) {
        return []
      }

      return [
        {
          title: `See ${this.alias || this.title}`,
          url: this.url,
        },
        ...this.children,
      ]
    },
  },
}
