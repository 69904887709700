
import { mapState, mapMutations } from 'vuex'
import { allowAllCookieValue, saveUserCookieSettings } from '~/utils/cookie'
import { STATES } from '~/ui/Elements/Button/WithState'

export default {
  name: 'ComponentsCookiesBanner',
  data() {
    return {
      lsScrollKey: 'cookie-accept-page-scroll-position',
      showModal: false,
      buttonState: STATES.default,
      timeout: null,
    }
  },
  computed: {
    ...mapState('behaviour', {
      showBanner: 'isCookieBannerVisible',
      cookieBannerOpenedOnDemand: 'cookieBannerOpenedOnDemand',
    }),
  },
  mounted() {
    if (!this.showBanner) {
      this.scrollPageToLastPosition()
    }
  },
  methods: {
    ...mapMutations('behaviour', {
      showCookieModal: 'showCookieModal',
      hideCookieBanner: 'hideCookieBanner',
    }),
    clickHandler() {
      this.buttonState = STATES.loading

      try {
        // store this cookie pref
        saveUserCookieSettings(allowAllCookieValue)
      } catch (err) {
        this.buttonState = STATES.error
        return
      }

      localStorage.setItem(this.lsScrollKey, window.pageYOffset)

      this.refreshPage()
    },
    refreshPage() {
      this.timeout = setTimeout(() => {
        this.buttonState = STATES.success
        // refresh the page in order for the tracking scripts removal to take effect
        window.location.reload()
      }, 1000)
    },
    scrollPageToLastPosition() {
      const scrollPositionBeforeReload = localStorage.getItem(this.lsScrollKey)
      if (scrollPositionBeforeReload) {
        window.scrollTo({
          top: scrollPositionBeforeReload,
        })
        localStorage.removeItem(this.lsScrollKey)
      }
    },
  },
}
