export const CUSTOM_TYPES = {
  contact: {
    url: '/contact',
    title: 'Contact Us',
  },
  'contact-thank-you': {
    url: '/contact/thank-you',
    title: 'Thank you for your contact request!',
  },
  'contact-error': {
    url: '/contact/error',
    title: `We're sorry you couldn't contact us!`,
  },
}
