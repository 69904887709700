
import { mapState, mapMutations } from 'vuex'
import { getUserCookieSettings, saveUserCookieSettings } from '~/utils/cookie'
import { STATES } from '~/ui/Elements/Button/WithState'

export default {
  data() {
    return {
      buttonState: STATES.default,
      cookieSettings: [],
      necessary: ['necessary'],
      cookieMap: {
        analytics: false,
        functionality: false,
      },
    }
  },
  computed: {
    ...mapState('behaviour', {
      isCookieModalVisible: 'isCookieModalVisible',
    }),
  },
  mounted() {
    const cookieSettings = this.getCookies()
    this.cookieSettings = Object.entries(cookieSettings).map(
      ([key, val]) => val && key
    )
  },
  methods: {
    ...mapMutations('behaviour', {
      hideCookieModal: 'hideCookieModal',
    }),
    getCookies() {
      return getUserCookieSettings()
    },
    saveHandler() {
      this.buttonState = STATES.loading

      try {
        // store this cookie pref
        Object.keys(this.cookieMap).forEach((cookie) => {
          this.cookieMap[cookie] = this.cookieSettings.includes(cookie)
        })
        saveUserCookieSettings(this.cookieMap)
      } catch (err) {
        this.buttonState = STATES.error
        return
      }

      this.timeout = setTimeout(() => {
        this.buttonState = STATES.success
        // refresh the page in order for the tracking scripts update to take effect
        window.location.reload()
      }, 1000)
    },
  },
}
