export const loadLatestDynamicPosts = async (store) => {
  try {
    await store.dispatch('pages/loadAllInsights')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching feature latest insights', e)
  }
  try {
    await store.dispatch('pages/loadAllEngineeringUpdates')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching feature latest engineering updates', e)
  }
  try {
    await store.dispatch('pages/loadAllPress')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching feature latest press posts', e)
  }
  try {
    await store.dispatch('pages/loadAllPeople')
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching feature latest culture posts', e)
  }
}
