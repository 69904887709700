import { render, staticRenderFns } from "./SectionLink.vue?vue&type=template&id=0105f150&"
import script from "./SectionLink.vue?vue&type=script&lang=js&"
export * from "./SectionLink.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsActionText: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/ActionText/index.vue').default,ComponentsLink: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Link/index.vue').default})
