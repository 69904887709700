
export default {
  props: {
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
    itemComponent: {
      type: [String, Function],
      required: false,
      default: 'div',
    },
    wrapperComponent: {
      type: [String, Function],
      required: false,
      default: 'div',
    },
    animationDelay: {
      type: Number,
      required: false,
      default: 100,
    },
    itemAnimationDuration: {
      type: Number,
      required: false,
      default: 50,
    },
  },
  data() {
    return {
      isVisible: {},
      timeoutRefs: [],
    }
  },
  computed: {
    visibleItems() {
      return [...this.items].filter((item, i) => this.isVisible[i])
    },
  },
  created() {
    this.show()
  },
  destroyed() {
    this.hide()
  },
  methods: {
    show() {
      this.items.forEach((item, i) => {
        this.timeoutRefs.push(
          setTimeout(() => {
            this.isVisible = {
              ...this.isVisible,
              [i]: true,
            }
          }, this.animationDelay + this.itemAnimationDuration * i)
        )
      })
    },
    hide() {
      this.timeoutRefs.forEach((ref) => clearTimeout(ref))
      this.timeoutRefs = []
      this.isVisible = {}
    },
    handleClick() {},
  },
}
