export const CUSTOM_TYPES = {
  'payments-europe': {
    url: '/europe',
    title: 'Europe Payments',
  },
  'sepa-credit-transfer': {
    url: '/europe/sepa-credit-transfer',
    title: 'Sepa Credit Transfer',
  },
  'sepa-direct-debit': {
    url: '/europe/sepa-direct-debit',
    title: 'Sepa Direct Debit',
  },
  'sepa-instant-payments': {
    url: '/europe/sepa-instant',
    title: 'Sepa Instant Payments',
  },
  sepa: {
    url: '/europe/sepa',
    title: 'Sepa Payments',
  },
}
