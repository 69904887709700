export const defaultLanguage = 'en-gb'
export const availableLanguages = ['en-gb', 'en-us']

export const isValidRoute = (route) => {
  if (!route || !route.params) {
    return false
  }

  const { lang } = route.params

  if (!lang) {
    return true
  }

  return lang && availableLanguages.includes(lang)
}

export const getLanguageFromRoute = (route) => {
  if (isValidRoute(route)) {
    return route.params.lang
  }

  return defaultLanguage
}
