export const CUSTOM_TYPES = {
  // root
  'payment-platform': {
    url: '/platform',
    title: 'Platform',
  },
  // international
  'payments-international': {
    url: '/platform/international',
    title: 'International Payments',
  },
  'payments-swift': {
    url: '/platform/international/swift',
    title: 'SWIFT',
  },
  'payments-cross-currency': {
    url: '/platform/international/cross-currency',
    title: 'Cross Currency',
  },
  'platform-connect': {
    url: '/platform/connect',
    title: 'Connect',
  },
  'platform-security': {
    url: '/platform/security',
    title: 'Security',
  },
  'platform-multicloud': {
    url: '/platform/multicloud',
    title: 'Multicloud',
  },
  'platform-escrow': {
    url: '/platform/escrow',
    title: 'Escrow',
  },
  'platform-simulator': {
    url: '/platform/simulator',
    title: 'Simulator',
  },
}
