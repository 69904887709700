
import { mapActions, mapState } from 'vuex'
import { isExternalLink } from '@/utils/links'
import themeMixin from '@/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    title: {
      type: String,
      required: true,
      default: null,
    },
    layout: {
      type: String,
      default: '',
      required: false,
    },
    widget: {
      type: Object,
      required: false,
      default: null,
    },
    description: {
      type: String,
      required: false,
      default: null,
    },
    children: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  computed: {
    ...mapState('header', {
      isMenuOpened: 'isDesktopMenuOpen',
      selectedDesktopMenu: 'selectedDesktopMenu',
    }),
    isExternalLink() {
      return isExternalLink(this.url)
    },
    hasChildren() {
      return this.children?.length > 0
    },
  },
  methods: {
    ...mapActions('header', {
      selectDesktopMenu: 'selectDesktopMenu',
      closeDesktopMenu: 'closeDesktopMenu',
    }),
  },
}
