export const CUSTOM_TYPES = {
  'payments-us': {
    url: '/united-states',
    title: 'US Payments',
  },
  'payments-us-instant': {
    url: '/united-states/us-instant',
    title: 'Instant',
  },
  'payments-us-details': {
    url: '/united-states/us',
    title: 'US',
  },
}
