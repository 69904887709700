
export default {
  props: {
    layout: {
      type: String,
      default: '',
      required: false,
    },
    items: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  computed: {
    component() {
      switch (this.layout) {
        case 'quarter':
          return 'ComponentsNavigationHeaderV2DesktopLayoutOneQuarter'
        case 'half':
        default:
          return 'ComponentsNavigationHeaderV2DesktopLayoutHalf'
      }
    },
  },
}
