
export default {
  props: {
    expandAt: {
      type: String,
      required: false,
      default: null,
    },
    expandAtWidth: {
      type: Number,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      isExpanded: true,
    }
  },
  computed: {
    isCollapsed() {
      if (this.expandAtWidth) {
        return this.$breakpoints?.width < this.expandAtWidth
      }

      return !this.$breakpoints[this.expandAt]
    },
    iconTheme() {
      return this.$store.state.theme.currentThemeIconContrast
    },
  },
  watch: {
    isCollapsed(isCollapsed) {
      this.isExpanded = !isCollapsed
    },
  },
  mounted() {
    this.isExpanded = !this.isCollapsed
  },
  methods: {
    toggle() {
      if (this.isCollapsed) {
        this.isExpanded = !this.isExpanded
      }
    },
  },
}
