import { render, staticRenderFns } from "./campaign.vue?vue&type=template&id=1f5de83f&"
import script from "./campaign.vue?vue&type=script&lang=js&"
export * from "./campaign.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsIcon: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Icon/index.vue').default,ComponentsLink: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Link/index.vue').default,LayoutSpacingHorizontal: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Layout/Spacing/Horizontal.vue').default,ComponentsNavigationFooter: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Navigation/Footer/index.vue').default,ComponentsCookiesBanner: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Cookies/Banner/index.vue').default,ComponentsCookiesModal: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Cookies/Modal/index.vue').default})
