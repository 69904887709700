export const CUSTOM_TYPES = {
  'payments-uk': {
    url: '/united-kingdom',
    title: 'UK Payments',
  },
  'payments-bacs': {
    url: '/united-kingdom/uk',
    title: 'BACS',
  },
  'payments-fps': {
    url: '/united-kingdom/uk-instant',
    title: 'Instant',
  },
}
