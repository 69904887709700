
import { mapState, mapGetters } from 'vuex'
import themeMixin from '@/mixins/theme'

export default {
  mixins: [themeMixin],
  computed: {
    ...mapState('header', {
      isMobileMenuOpen: 'isMobileMenuOpen',
    }),
    ...mapState('featureFlags', {
      showCareersAlert: 'show_careers_alert',
    }),
    ...mapGetters('menu', {
      desktopMenu: 'desktopMenu',
      mobileMenu: 'mobileMenu',
    }),
    themeClass() {
      return {
        [`bg-${this.themeColor}`]: !this.isMobileMenuOpen,
        [`bg-${this.getAccessibilityContrast(this.themeColor)}`]:
          this.isMobileMenuOpen,
        [`lg:bg-${this.themeColor} text-${this.getContrastColor(
          this.themeColor
        )}`]: true,
      }
    },
  },
}
