export const loadMenu = async ($prismic, store) => {
  try {
    const menu = await $prismic.api.getByUID('menu', 'menu-uid-4')
    if (menu) {
      store.dispatch('menu/setMenu', menu.data)
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('Error fetching feature menu', e)
  }
}
