import { mapGetters } from 'vuex'

export default {
  head() {
    return {
      bodyAttrs: {
        class: this.bodyClasses,
      },
    }
  },
  computed: {
    ...mapGetters('behaviour', {
      isScrollBarVisible: 'isScrollBarVisible',
    }),
    bodyClasses() {
      if (this.isScrollBarVisible) {
        return []
      }

      return ['overflow-hidden']
    },
  },
}
