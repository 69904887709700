const NUXT_ENV_PRISMIC_PROD = process.env.NUXT_ENV_PRISMIC_PROD
export const state = () => ({
  prismicGraphQLEndpoint: NUXT_ENV_PRISMIC_PROD
    ? 'https://form3.cdn.prismic.io/graphql'
    : 'https://form3-dev.cdn.prismic.io/graphql',
})

export const mutations = {
  update(state, payload) {
    Object.assign(state, payload)
  },
}
