export const CUSTOM_TYPES = {
  careers: {
    url: '/careers',
    title: 'Careers',
  },
  vacancies: {
    url: '/careers/vacancies',
    title: 'Vacancies',
  },
  vacancy: {
    url: '/careers/vacancies/:jobId',
    title: 'Vacancy',
  },
}
