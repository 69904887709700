
import themeMixin from '@/mixins/theme'

export default {
  mixins: [themeMixin],
  props: {
    links: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
}
