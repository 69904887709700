
import themeMixin from '~/mixins/theme'
export default {
  name: 'Button',
  mixins: [themeMixin],
  props: {
    icon: {
      type: String,
      required: false,
      default: 'arrow-right',
    },
    hasIcon: {
      type: Boolean,
      required: false,
      default: true,
    },
    iconClass: {
      type: [String, Object],
      required: false,
      default: '',
    },
    theme: {
      type: String,
      required: false,
      default: 'soft-white',
    },
  },
  computed: {
    themeComputedClass() {
      const disabledClass = this.$attrs.disabled
        ? 'pointer-events-none opacity-60'
        : ''
      return `${this.getThemeClass(
        this.theme || this.themeColor
      )} ${disabledClass}`
    },
    computedIcon() {
      return `${this.icon}-${this.getIconContrastColor(this.theme)}`
    },
  },
  mounted() {
    this.$emit('mounted')
  },
}
