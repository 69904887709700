import { render, staticRenderFns } from "./default.vue?vue&type=template&id=06b2d86a&"
import script from "./default.vue?vue&type=script&lang=js&"
export * from "./default.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentsNavigationHeaderV2: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Navigation/HeaderV2/index.vue').default,ComponentsNavigationFooter: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Navigation/Footer/index.vue').default,ComponentsCookiesBanner: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Cookies/Banner/index.vue').default,ComponentsCookiesModal: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Cookies/Modal/index.vue').default})
