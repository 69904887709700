import { userHasCookieSettings } from '~/utils/cookie'
export default {
  mounted() {
    if (userHasCookieSettings()) {
      this.$store.commit('behaviour/hideCookieBanner')
    } else {
      this.$store.commit('behaviour/showCookieBanner')
    }
  },
}
