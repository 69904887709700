import { render, staticRenderFns } from "./SectionList.vue?vue&type=template&id=32efa761&"
import script from "./SectionList.vue?vue&type=script&lang=js&"
export * from "./SectionList.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ComponentsNavigationHeaderV2MobileTabs: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Navigation/HeaderV2/Mobile/Tabs/index.vue').default,ComponentsNavigationHeaderV2MobileSection: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Navigation/HeaderV2/Mobile/Section.vue').default,ComponentsAnimatedList: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/AnimatedList/index.vue').default})
