export const CUSTOM_TYPES = {
  // this is only needed for the breadcrumbs to work
  // the news root page does not exist
  // and it's just a redirect to one of the child pages
  'news-root': {
    url: '/news',
    title: 'News',
  },
  insights: {
    url: '/news/payment-insights',
    title: 'Payment insights',
  },
  press: {
    url: '/news/press-releases',
    title: 'Press Releases',
  },
}

export const DYNAMIC_CUSTOM_TYPES = {
  marketing_insights_post: {
    url: (uid) => {
      return `/news/payment-insights/${uid}`
    },
    title: 'Insight',
  },
  press_post: {
    url: (uid) => {
      return `/news/press-releases/${uid}`
    },
    title: 'Press',
  },
}
