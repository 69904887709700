export const CUSTOM_TYPES = {
  // this is only needed for the breadcrumbs to work
  // the blog root page does not exist
  // and it's just a redirect to one of the child pages
  'blog-root': {
    url: '/blog',
    title: 'Blog',
  },
  'engineering-content': {
    url: '/blog/engineering',
    title: 'Engineering blog',
  },
  people: {
    url: '/blog/culture',
    title: 'Culture Blog',
  },
}

export const DYNAMIC_CUSTOM_TYPES = {
  engineering_update_post: {
    url: (uid) => {
      return `/blog/engineering/${uid}`
    },
    title: 'Engineering Update',
  },
  people_post: {
    url: (uid) => {
      return `/blog/culture/${uid}`
    },
    title: 'culture',
  },
}
