// the number of days after which a post is not considered new any more
export const NEW_POST_DAYS_COUNT = 7

export const getDaysDifference = (date1, date2) => {
  return Math.floor((date1 - date2) / (24 * 60 * 60 * 1000))
}

export const isNewPost = (post) => {
  if (!post || !post.first_publication_date) {
    return false
  }

  return (
    getDaysDifference(new Date(), new Date(post.first_publication_date)) <=
    NEW_POST_DAYS_COUNT
  )
}
