
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
  },

  computed: {
    src() {
      try {
        return require(`~/assets/icons/${this.icon}.svg`)
      } catch (err) {
        // eslint-disable-next-line no-console
        console.warn('invalid icon name:', this.icon)
        return null
      }
    },
  },
}
