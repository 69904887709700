
import themeMixin from '~/mixins/theme'
export default {
  mixins: [themeMixin],
  inheritAttrs: false,
  model: { prop: 'options' },
  props: {
    label: {
      type: String,
      required: false,
      default: '',
    },
    value: {
      type: [String, Boolean, Array],
      required: false,
      default: false,
    },
    options: {
      type: [Array],
      required: false,
      default: () => [],
    },
    theme: {
      type: String,
      required: false,
      default: null,
    },
  },
  data() {
    return {
      randomId: null,
    }
  },
  computed: {
    selected: {
      get() {
        return this.options
      },
      set(val) {
        this.$emit('input', val)
      },
    },
    listeners() {
      const { input, ...rest } = this.$listeners

      return rest
    },
    checked() {
      return this.options.includes(this.value) ? 'checked' : 'default'
    },
    disabled() {
      return {}.propertyIsEnumerable.call(this.$attrs, 'disabled')
    },
    currentTheme() {
      return this.theme || this.themeColor
    },
    textColor() {
      return this.getContrastColor(this.currentTheme)
    },
    iconColor() {
      return this.getIconContrastColor(this.currentTheme)
    },
  },
  mounted() {
    // Generate a random id to associate the input to the label
    this.randomId = (Math.random() + 1).toString(36).substring(7)
  },
}
