
import { isExternalLink } from '~/utils/links'
import { getLanguageFromRoute, defaultLanguage } from '~/utils/language'

export default {
  name: 'ComponentsLink',
  props: {
    to: {
      type: String,
      required: true,
      default: '',
    },
    title: {
      type: String,
      required: false,
      default: '',
    },
  },
  computed: {
    isExternalLink() {
      return isExternalLink(this.to)
    },
    component() {
      if (this.isExternalLink) {
        return 'a'
      }

      return `nuxt-link`
    },
    attrs() {
      if (this.isExternalLink) {
        return {
          href: this.to,
          target: '_blank',
        }
      }

      return {
        to: this.computedTo,
      }
    },
    lang() {
      return getLanguageFromRoute(this.$route)
    },
    computedTo() {
      const langRoutePath =
        !this.lang || this.lang === defaultLanguage ? '' : `/${this.lang}`
      const separator = this.to[0] === '/' ? '' : '/'

      return `${langRoutePath}${separator}${this.to}`
    },
  },
}
