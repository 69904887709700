import { render, staticRenderFns } from "./Tab.vue?vue&type=template&id=3777ffe3&"
import script from "./Tab.vue?vue&type=script&lang=js&"
export * from "./Tab.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {ElementsIcon: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Elements/Icon/index.vue').default,LayoutSpacingHorizontal: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Layout/Spacing/Horizontal.vue').default,ComponentsNavigationHeaderV2MobileTabsSection: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/Navigation/HeaderV2/Mobile/Tabs/Section.vue').default,ComponentsAnimatedList: require('/home/runner/work/form3-website-frontend/form3-website-frontend/ui/Components/AnimatedList/index.vue').default})
